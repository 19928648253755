import React from 'react';

const ViewCurrentStock = () => {
    return (
        <div>
            <h1>ljdnfd</h1>
        </div>
    );
};

export default ViewCurrentStock;